import React from 'react';
import { Link } from 'react-router-dom';
import textService from '../services/text';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';

const Contact = () => {
  return (
    <div className="patio-purpura patio-purpura--contact">
        <Header></Header>

        <main className="patio-purpura-content">

          <div className='patio-purpura-content-wrapper'> 
            
            <p className="text">{textService.getContactDescription()}</p>
            
            <Link 
              className="email"
              to="#"
              onClick={(e) => {
                window.location.href = 'mailto:' + textService.getEmail();
                e.preventDefault();
              }}
            >
              { textService.getEmail() }
            </Link>
          </div>
          
        </main>

        <Footer></Footer>

      </div>
  );
}

export default Contact;
