import React from 'react';
import { Link } from 'react-router-dom';
import textService from '../../services/text';
import logo_footer from '../../assets/logo-footer.png';
import logo_instagram from '../../assets/in.svg';

const Footer = () => {
  return (
    <footer className="patio-purpura-footer">
      <img 
        alt="Patio Púrpura Logo" 
        className="patio-purpura-footer-logo" 
        src={ logo_footer }
        title="Patio Púrpura Logo" />

        <Link 
          className="email"
          to="#"
          onClick={(e) => {
            window.location.href = 'mailto:' + textService.getEmail();
            e.preventDefault();
          }}
        >
          { textService.getEmail() }
        </Link>

        <Link
          className="patio-purpura-instagram"
          to="https://www.instagram.com/patiopurpura"
          target="_blank"
        >
          <img 
            alt="Patio Púrpura Instagram" 
            className="patio-purpura-instragram" 
            src={ logo_instagram }
            title="Patio Púrpura Instagram" />
        </Link>

        <p className="copyright">2024</p>
    </footer>
  );
}

export default Footer;
