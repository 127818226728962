const TITLE = '¿Qué es Patio Púrpura?';

const DESCRIPTION = "Emprendimiento de una tica amante del pan y del buen café. " + 
"Pan artesanal hecho en casa con paciencia y amor. " +  
"Porque todo lo bueno toma su tiempo.";

const EMAIL = "patiopurpura@gmail.com";

const DESCRIPTION_CONTACT = "Puedes contactárnos por medio del correo electrónico:";

const WHAT_IS_SOURDOUGH_TITLE = "¿Qué es la masa madre?";

const BENEFITS_TITLE = "Beneficios";

const SOURDOUGH = [
  {
    id: 0,
    title: "Es un fermento natural",
    description: "Debido a los microorganismos que crean el fermento, los cuales funcionan como probióticos intestinales."
  },
  {
    id: 1,
    title: "Una forma de leudar el pan (sin levaduras agregadas)",
    description: "",
  },
];

const BENEFITS = [
  {
    id: 0,
    title: "Mejoran la salud intestinal y la absorción de nutrientes",
    description: "Debido a los microorganismos que crean el fermento, los cuales funcionan como probióticos intestinales."
  },
  {
    id: 1,
    title: "Control del índice glucémico",
    description: "Algunas baterias durante su actividad ingieren ciertos carbohidratos de la harina",
  },
  {
    id: 2,
    title: "Contiene ácidos orgánicos que contribuyen a conservar sus valores nutricionales",
    description: "",
  },
];

const textService = {

  getMainTitle: () => {
    return TITLE;
  },

  getWhatIsSourdoughTitle() {
    return WHAT_IS_SOURDOUGH_TITLE;
  },

  getBenefitsTitle() {
    return BENEFITS_TITLE;
  },

  getMainDescription: () => {
    return DESCRIPTION;
  },

  getContactDescription: () => {
    return DESCRIPTION_CONTACT;
  },

  getSourdoughExplanation: () => {
    return SOURDOUGH;
  },

  getSourdoughBenefits: () => {
    return BENEFITS;
  },

  getEmail: () => {
    return EMAIL;
  },

};

export default textService;
