import React from 'react';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="patio-purpura-header">
      <img 
        alt="Patio Púrpura Logo" 
        className="patio-purpura-header-logo" 
        src={ logo }
        title="Patio Púrpura Logo" />    

        <div className='main-menu'>
          <Link to={ "/" }>Home</Link>
          <Link to={ "/contactenos" }>Contáctenos</Link>
        </div>
    </header>
  );
}

export default Header;
