import React from 'react';
import './list.scss';

const List = ({ items = null }) => {
  return (
    <ul className="list">
      {
        items.map(listItem => (
          <li key={listItem.id} className="list-item">{ listItem?.title }</li>
        ))
      }
    </ul>
  );
}

export default List;
