import React from 'react';
import './image.scss';

const Image = ({
  alt = null,
  title = null, 
  src = null
}) => {
  return (
    <div className="patio-purpura-image">
      <img 
        src={ src } 
        alt={ alt }
        title={ title }
      />
    </div>
  );
}

export default Image;
