import React from 'react';
import textService from '../services/text';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Title from '../components/title/title';
import List from '../components/list/list';
import Description from '../components/description/description';
import Image from '../components/image/image';
import panFrutosRojos from '../assets/pan-frutos-rojos.jpg';


const Home = () => {
  return (
    <div className="patio-purpura">
        <Header></Header>

        <main className="patio-purpura-content">
          <Title myTitle={textService.getMainTitle()}></Title>
          <Description description={textService.getMainDescription()}></Description>

          <Image 
            className="pan-masa-madre" 
            src={ panFrutosRojos } 
            alt="Pan Frutos Rojos" 
            title="Pan Frutos Rojos"
          ></Image>
          
          <Title myTitle={textService.getWhatIsSourdoughTitle()}></Title>
          <List items={textService.getSourdoughExplanation()}></List>
          
          <Title myTitle={textService.getBenefitsTitle()}></Title>
          <List items={textService.getSourdoughBenefits()}></List>
        </main>

        <Footer></Footer>

      </div>
  );
}

export default Home;
