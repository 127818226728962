import './App.scss';

import {
  BrowserRouter as Router, 
  Route, 
  Routes
} from 'react-router-dom';
import Home from './pages/home';
import Contact from './pages/contact';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/contactenos" element={ <Contact /> } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
