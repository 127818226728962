import React from 'react';
import './description.scss';

const Description = ({ description = null }) => {
  return (
    <div className="description">
      <p>{ description }</p>
    </div>
  );
}

export default Description;
