import React from 'react';
import './title.scss';

const Title = ({ myTitle = null }) => {
  return (
    <div className="title">{ myTitle }</div>
  );
}

export default Title;
